import React, { createContext, useContext, useState, useEffect } from 'react';

const DevModeContext = createContext();

export function DevModeProvider({ children }) {
  const [isDevMode, setIsDevMode] = useState(() => {
    return process.env.REACT_APP_MODE === 'development';
  });

  useEffect(() => {
    const mode = process.env.REACT_APP_MODE;
    setIsDevMode(mode === 'development');
  }, []);

  return (
    <DevModeContext.Provider value={{ isDevMode }}>
      {children}
    </DevModeContext.Provider>
  );
}

export function useDevMode() {
  const context = useContext(DevModeContext);
  if (context === undefined) {
    throw new Error('useDevMode must be used within a DevModeProvider');
  }
  return context;
}