import React, { useRef } from 'react';
import { ArrowUpDown, X } from 'lucide-react';
import useClickOutside from '../../hooks/useClickOutside';
import './SortPopover.css';

const SortPopover = ({ currentSort, setSort, onClose }) => {
  const popoverRef = useRef();
  useClickOutside(popoverRef, onClose);

  const sortOptions = [
    { value: 'newest', label: 'Newest First', icon: ArrowUpDown },
    { value: 'oldest', label: 'Oldest First', icon: ArrowUpDown },
    { value: 'mostEngaged', label: 'Most Engaged', icon: ArrowUpDown },
    { value: 'leastEngaged', label: 'Least Engaged', icon: ArrowUpDown }
  ];

  return (
    <div ref={popoverRef} className="sort-popover absolute right-0 mt-2 w-56 rounded-xl shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 z-10">
      <div className="p-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-bold text-gray-900 dark:text-white">Sort By</h3>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300">
            <X size={20} />
          </button>
        </div>
        <div className="space-y-3">
          {sortOptions.map((option) => (
            <label key={option.value} className="flex items-center group cursor-pointer">
              <div className="relative">
                <input
                  type="radio"
                  checked={currentSort === option.value}
                  onChange={() => {
                    setSort(option.value);
                    onClose();
                  }}
                  className="sr-only"
                />
                <div className="twitter-radio bg-white dark:bg-gray-700 border-2 border-gray-300 dark:border-gray-600 rounded-full w-5 h-5 focus:outline-none transition-all duration-200 ease-in-out group-hover:border-twitter-blue dark:group-hover:border-twitter-blue-dark">
                  {currentSort === option.value && (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="w-2.5 h-2.5 bg-twitter-blue dark:bg-twitter-blue-dark rounded-full" />
                    </div>
                  )}
                </div>
              </div>
              <span className="ml-3 text-sm text-gray-700 dark:text-gray-300 group-hover:text-gray-900 dark:group-hover:text-white transition-colors duration-200">
                {option.label}
              </span>
            </label>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SortPopover;